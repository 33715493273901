import React from 'react'
import { Link } from 'react-router-dom'
import { HiMiniArrowLongLeft } from "react-icons/hi2";
import AtmosphereImage from '../assets/images/atmosphere-mockup.png'

function CasestudyBanner({bannerSmallHeading, bannerLargeHeading, showLargeHeading=true, showBannerImage=true, bannerBackground, smallHeading, solfinImage}) {
  return (
    <>
      <section>
            <div className={`digitech-hero-banner atmosphere-banner  header-space ${bannerBackground}`}>
                <div className='container'>
                    <div className='solfin-banner-wrapper d-flex align-items-center'>
                        <div className='casestudy-banner-wrapper'>
                            <div className='atmosophere-banner-text'>
                                <span className={`atmosphere-small-heading text-uppercase ${smallHeading}`}>{bannerSmallHeading}</span>
                                {showLargeHeading ? (<h2>{bannerLargeHeading}</h2>):(<h2 className='solfin-heading'>{bannerLargeHeading}</h2>)}
                            </div>
                        </div>
                        <div className='banner-right'>
                            {showBannerImage ? (<div className='banner-img'><img src={AtmosphereImage} title='atmosphere' alt='atmosphere' className='w-100' /></div>):(<div className='banner-img solfin-bg'><img src={solfinImage} title='atmosphere' alt='atmosphere' className='w-100' /></div>)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default CasestudyBanner
