import React from 'react'
import { useSpring, animated } from 'react-spring';

function TabImage({imagearray,imageUrl}) {
  const props = useSpring({
    x: 0,
    duration: 2000,
    from: { x: 300, duration: 0 },
    // Add more properties for other animations (e.g., translate, scale)
  });
  return (
    <>
      <animated.div style={props}
      className='right-side-image h-100'>
            <img src={imageUrl} title='' alt='' />
        </animated.div>
        {/* {imagearray.map(img =>(
            <div className='right-side-image'>
            <img src={img.imageUrl} title='' alt='' />
        </div>
        ))} */}
    </>
  )
}

export default TabImage
