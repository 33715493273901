import React from 'react'
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import TabImage from './TabImage';


function ExploreAtmosphereTabs  ( { tabArray=[] }  )  {

    console.log("explore Atmosphere component is loaded ")
    const [activeTab, setActiveTab] = React.useState(0);
    const onTabClick = (e, index) => {
        console.log(e);
        setActiveTab(index);
    };
    
    console.log("tab array is  : ",tabArray)
  return (
    <>
    <Tabs activeTab={activeTab} onTabClick={onTabClick} tabsArray={tabArray}>
        {/* generating an array to loop through it  */}

        {tabArray.map((item) => (
            <Tab key={item.coverId} className={`item ${activeTab === item.coverId ? 'active text-start' : 'text-start'}`}><span className='serial-number'>{item.serialNumber}</span><span className='tab-name'>{item.name}</span>
                <p>{item.para}</p>
            </Tab>
        ))}
    </Tabs>

    
    {tabArray.map((screen)=>(
    <TabScreen
    key={screen.coverId}
    activeTab={activeTab}
    index={screen.coverId}

    // You can add animation with adding a custom class
    >
    <TabImage imageUrl={screen.imageUrl} />
    </TabScreen>
    ))}
    </>
  )
}

export default ExploreAtmosphereTabs
