import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { BsChatText } from "react-icons/bs";

function Footer() {
  return (
    <>
        <footer className='pb-3 pt-3'>
            <div className='container-fluid custom-container-fluid'>
                {/* <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='footer-links'>
                            <div className='footer-heading mb-3'>
                                <h5 className='theme-font-family work-heading fw-600'>Services</h5>
                            </div>
                            <ListGroup as="ul">
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>Branding</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>UIUX Design</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>Packaging</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>Development</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>Marketing</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>Video</Link></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='footer-links'>
                            <div className='footer-heading mb-3'>
                                <h5 className='theme-font-family work-heading fw-600'>Other Links</h5>
                            </div>
                            <ListGroup as="ul">
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>About Us</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>Blogs</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>FAQs</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>Career</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>Privacy</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/'>Terms of use</Link></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                    
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <div className='social-links'>
                            <div className='footer-heading mb-3'>
                                <h5 className='theme-font-family work-heading fw-600 mb-3'>Call Us</h5>
                                <span className='py-1'>0172 4649954</span>
                            </div>
                            <div className='social-media-wrapper'>
                                <h5 className='theme-font-family work-heading fw-600 mb-3 '>Follow us</h5>
                                <ListGroup as="ul" className='social-media-links d-flex flex-row'>
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 py-0'><Link to='/'><FaFacebookF /></Link></ListGroup.Item>
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 py-0'><Link to='/'><FaXTwitter /></Link></ListGroup.Item>
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 py-0'><Link to='/'><FaLinkedinIn /></Link></ListGroup.Item>
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 py-0'><Link to='/'><FaInstagram /></Link></ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <div className='social-links'>
                            <div className='footer-heading mb-3'>
                                <h5 className='theme-font-family work-heading fw-600 mb-3'>Mail Us</h5>
                                <Link to='/' className='py-1 text-dark text-decoration-none'>info@digitechsoft.com</Link>
                            </div>
                            <div className='social-media-wrapper'>
                                <h5 className='theme-font-family work-heading fw-600 mb-3 '>subscribe</h5>
                                <Form className='d-flex'>
                                    <Form.Control className='custom-input rounded-0 py-3'
                                        type="text"
                                        placeholder="Email Address"
                                        aria-label="Disabled input example"
                                        readOnly
                                    />
                                    <Button variant="primary" type="submit" className='btn consult-btn rounded-0 border-0'>
                                        Submit
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='copyright border-0 mt-0'>
                    <div className='row align-items-center'>
                        <div className='col-lg-12'>
                            <div className='copyright-left text-center'>
                                <span>© Digitech Software Solutions 2024. All Rights Reserved.</span>
                            </div>
                        </div>
                        {/* <div className='col-lg-6'>
                            <div className='social-media-wrapper text-end'>
                                <ListGroup as="ul" className='social-media-links d-flex flex-row justify-content-end'>
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 py-0'><Link to='/'><FaFacebookF /></Link></ListGroup.Item>
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 py-0'><Link to='/'><FaXTwitter /></Link></ListGroup.Item> 
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 py-0'><Link to='/'><FaLinkedinIn /></Link></ListGroup.Item>
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 py-0'><Link to='/'><FaInstagram /></Link></ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <div className='back-top-btn'>
                <Link to='/' className='theme-btn footer-btn'>Back to Top</Link>
            </div> */}
            
            
        </footer>        
        {/* <div className='consult-btn-wrapper text-end'>
            <Link to='/consult' className='btn consult-bottom-btn'><BsChatText /></Link>
        </div> */}
    </>
  )
}

export default Footer
