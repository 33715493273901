import {React, useState} from 'react'
import { Link} from 'react-router-dom'
import { Nav, Navbar } from 'react-bootstrap-v5'
import MenuImage from '../assets/images/logo.svg' 
import DropdownImage from '../assets/images/web-development.svg'
import DropdownImageOne from '../assets/images/mob-app-development.svg'

const Header = () => {
    const [expanded, setExpanded] = useState(false);

    const dropdowndata = [
        {DropdownHeading: 'Web Development', DropdownPara:'Lorem ipsum dolor sit amet elit', DropdownLink:'/webdevelopment', dropdownImage:DropdownImage},
        {DropdownHeading: 'Software Development', DropdownPara:'Lorem ipsum dolor sit amet elit', leftBox:'left-box'},
        {DropdownHeading: 'Mob App Development', DropdownPara:'Lorem ipsum dolor sit amet elit', dropdownImage:DropdownImageOne},
        {DropdownHeading: 'Digital Marketing', DropdownPara:'Lorem ipsum dolor sit amet elit', leftBox:'left-box'},
        {DropdownHeading: 'UIUX Design', DropdownPara:'Lorem ipsum dolor sit amet elit', leftBox:'left-box'},
        {DropdownHeading: 'Branding & Communication', DropdownPara:'Lorem ipsum dolor sit amet elit', leftBox:'left-box'},
        {DropdownHeading: 'Unified CMS', DropdownPara:'Lorem ipsum dolor sit amet elit', leftBox:'left-box'}
    ]

    return (
      <>
        <header>
          <div className='container-fluid custom-container-fluid'>
            <div className='row align-items-center'>
              <div className='col-lg-3 col-md-3'>
                  <Link to='javascript:void(0);' className='menu-image'>
                    <img src={MenuImage} alt='' title='' />
                  </Link> 
              </div>
              <div className='col-lg-9 col-md-9 responsive-display'>
                <div id='digitech-nav' className='digitech-responsive-nav d-flex align-items-center justify-content-end'>
                  <Navbar.Toggle className="shadow-none navbar-toggle" aria-controls="basic-navbar-nav" onClick={()=>setExpanded(prev=>!prev)} />
                  <Navbar expanded={expanded} expand="lg" className="navbar-nav smooth p-0">
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav as='ul' className="me-auto d-flex align-items-center">
                          {/* <Nav.Item as='li'>
                            <NavDropdown title="Services" id="basic-nav-dropdown" className='custom-dropdown'>
                              <HeaderDropdown dropdowndata={dropdowndata} />
                            </NavDropdown>
                          </Nav.Item> */}
                          {/* <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='nav-link' to="/product">Products</NavLink></Nav.Item>
                          <Nav.Item as='li'>
                            <NavDropdown title="Case Study" id="basic-nav-dropdown" className='custom-dropdown'>
                              <HeaderDropdown dropdowndata={dropdowndata} />
                            </NavDropdown>
                          </Nav.Item>
                          <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='nav-link' to="/portfolio">Portfolio</NavLink></Nav.Item>
                          <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='nav-link'  to='/contact-us'>Blog</NavLink></Nav.Item> */}
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }

export default Header