import React from 'react'

function AboutClient({aboutdata}) {
    return (
        <>
            {aboutdata.map(item =>(
                <section>
                    <div className='about-atmosphere'>
                        <div className='container'>
                            <div className='atmosphere-heading'>
                                <h2>{item.AboutHeading}<span className="brand-color">.</span></h2>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <div className='about-left-text ozen-about'>
                                            <span className='text-uppercase fw-medium'>{item.SecondSmallHeading}</span>
                                            <h3 className='atmosphere-inner-heading text-uppercase'>{item.SecondLargeHeading}</h3>
                                        </div>
                                    </div>
                                    <div className='col-lg-7'>
                                        <div className='about-paragraph'>
                                            <p className='mb-0'>{item.AboutPara}</p>
                                            <p className='mt-4 mb-0'>{item.AboutParaSecond}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        </>
    )
}

export default AboutClient
