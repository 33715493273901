import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup';

function ChallengeList({listdata}) {
    return (
        <>
            {listdata.map(item => (
                <ListGroup>
                    <ListGroup.Item className='d-flex mb-5'><span className='d-inline-block fw-semibold fs-4 me-4'>{item.SerialNumber}</span><p className='mb-0'>{item.ListParaOne}</p></ListGroup.Item>
                </ListGroup>
            ))}
        </>
    )
}

export default ChallengeList
