import React from 'react'
import { Route, Routes } from 'react-router-dom'
import OzenCollection from '../pages/case-study/OzenCollection';
 
function AllRoutes() {
  return (
    <>
        <Routes>
            <Route path='/' element={<OzenCollection/>}></Route>
        </Routes>
    </>
  )
}

export default AllRoutes
