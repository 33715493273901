import React from 'react'
import { Link} from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md';

function ConsultUs({showheading=true, showSmallHeading=true}) {
  return (
    <>
        <section>
            <div className='consult-main-wrapper'>
                <div className='container-fluid custom-container-fluid'>
                    <div className='consult-wrapper'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                {showSmallHeading ? (<h6 className='subbrand-color text-uppercase theme-font-family'>Elevating luxury hospitality </h6>):(<h6 className='brand-color text-uppercase theme-font-family'>Consult Us</h6>)}
                                {showheading ? 
                                (<h2 className='mb-4 small-heading'>THE OZEN COLLECTION's Comprehensive Approach</h2>):
                                (<h2 className='mb-4 large-heading'>Interested to have us work for you?</h2>)}
                                
                                <p>Seamless integration of intuitive tools adds restaurant offerings and villas effortlessly, while enhanced image quality captivates. This approach streamlines operations, boosts inquiries, and positions The OZEN COLLECTION as a pinnacle of luxury hospitality.
</p>
                            </div>
                            {/* <div className='col-lg-3'>
                                <div className='consult-btn-wrapper d-flex align-item-center justify-content-end'>
                                    <Link to='/consult' className='btn consult-btn'>Consult Us <MdArrowOutward /></Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>  
            </div>
        </section>
    </>
  )
}

export default ConsultUs
